import React, { useEffect, useRef, useState } from 'react';
import { LinearGradient } from 'react-text-gradients';
import ETClientList from './ETClientList';
import ClientNameSlide from './ClientNameSlide';

import UsrFrnd from '../images/usrFrnd.jpg';
import ProcImg from '../images/processImg.jpg';
import Ebill from '../images/ebill.png';
import MLoc from '../images/mLocatation.png';
import SMS from '../images/sms.png';
import Report from '../images/report.png';
import Business from '../images/business.png';
import Telly from '../images/tallyInte.png';
import ETimg from '../images/EasyTrade.png';
import easyPose from '../images/easyPos.png';
import easyClub from '../images/easyPosClub.png';
import Member from '../images/membericon.png';
import CockFood from '../images/chefcookingfood.png';
import barGlass from '../images/barGlass.png';
import Games from '../images/indoorGame.png';
import eClubReport from '../images/reportEclub.png';
import clubBook from '../images/clubBook.png';
import ProdMag from '../images/prodMag.png';
import InveMang from '../images/inventoryMang.png';
import OrdMang from '../images/ordMang.png';
import BarQRCode from '../images/bar&qrCode.png';
import ReceivePayment from '../images/receivePayment.png';
import Catloge from '../images/catalouge.png';
import ScrnVarient from '../images/scrnVarient.png';
import Bilty from '../images/biltyImg.png';
import JobPlan from '../images/jobPlan.png';
import DsgnStylMang from '../images/dsgnStyleMang.png';

import { WorkDetail } from '../images/SvgIcon/Svg';
import { IoArrowUpCircleOutline } from "react-icons/io5";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate } from 'react-router-dom';
gsap.registerPlugin(ScrollTrigger);

export default function Product() {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const easyPoseRef = useRef(null);
    const easyTradeRef = useRef(null);
    const easyEclubRef = useRef(null);

    useEffect(() => {
        switch (activeTab) {
            case 'easyPose':
                easyPoseRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'easyTrade':
                easyTradeRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'easyEclub':
                easyEclubRef.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    }, [activeTab]);

    const testimonialRef1 = useRef(null);
    const testimonialRef2 = useRef(null);
    const testimonialRef3 = useRef(null);
    const testimonialRef4 = useRef(null);
    const testimonialRef5 = useRef(null);
    const testimonialRef6 = useRef(null);
    const testimonialRef7 = useRef(null);
    const testimonialRef8 = useRef(null);
    const testimonialRef9 = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const windowTopPosition = window.scrollY;
            const windowBottomPosition = windowTopPosition + windowHeight;

            const elements = [testimonialRef1.current, testimonialRef2.current, testimonialRef3.current, testimonialRef4.current, testimonialRef5.current, testimonialRef6.current, testimonialRef7.current, testimonialRef8.current, testimonialRef9.current];

            elements.forEach((element) => {
                if (!element) return;
                const elementHeight = element.offsetHeight;
                const elementTopPosition = element.offsetTop;
                const elementBottomPosition = elementTopPosition + elementHeight;
                if (
                    elementBottomPosition >= windowTopPosition &&
                    elementTopPosition <= windowBottomPosition
                ) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const texts = [
        'Facility Center', 'Shift Management', 'Booking Feature', 'Room/Lawn Booking', 'Reservation & Room Availability',
    ];

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className='container-fluid mt-5'>
                <div className='row py-5'>
                    <div className='text-center mt-5 p-5' style={{ fontSize: "2em", fontWeight: 600, }}>
                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                            GET THE HIGHLIGHTS
                        </LinearGradient>
                    </div>
                    <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                </div>
            </div>
            <div className='container'>
                <div className='row animation-element slide-up testimonial' ref={testimonialRef7}>
                    <div className='mb-5'>
                        <p className='text-center' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }}>
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Innovative Software for the Industry-Specific with Tailored Software Solutions
                            </LinearGradient>
                        </p>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                        <div className='card border-0 p-5 align-items-center prod-card' onClick={() => handleTabChange('easyTrade')} style={{ width: "100%", background: activeTab === 'easyTrade' ? "linear-gradient(to right, #1c98da, #80aee5, #b6c6ee, #dfe1f6, #ffffff)" : "#111212", cursor: "pointer" }}>
                            <img src={ETimg} alt="" style={{ width: "65%" }} />
                        </div>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                        <div className='card border-0 p-5 align-items-center prod-card' onClick={() => handleTabChange('easyPose')} style={{ width: "100%", background: activeTab === 'easyPose' ? "linear-gradient(to right, #1c98da, #80aee5, #b6c6ee, #dfe1f6, #ffffff)" : "#111212", cursor: "pointer" }}>
                            <img src={easyPose} alt="" style={{ width: "100%" }} />
                        </div>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                        <div className='card border-0 p-5 align-items-center prod-card' onClick={() => handleTabChange('easyEclub')} style={{ width: "100%", background: activeTab === 'easyEclub' ? "linear-gradient(to right, #1c98da, #80aee5, #b6c6ee, #dfe1f6, #ffffff)" : "#111212", cursor: "pointer" }}>
                            <img src={easyClub} alt="" style={{ width: "69%" }} />
                        </div>
                    </div>
                </div>
                {/* Conditional Rendering of Content Based on Active Tab */}
                <div ref={easyTradeRef} style={{ paddingTop: 110 }} >
                    {activeTab === 'easyTrade' && (
                        <>
                            <div className='container'>
                                <div className='row'>
                                    <div className='text-center' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                            Easy Trade
                                        </LinearGradient>
                                    </div>
                                    <div className='text-center' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} > Software Solution for</div>
                                    <div className='text-center pb-5' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} > Apparel (Readymade) and Sarees</div>
                                    <div className='text-center pb-5' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 500, color: "#17acff" }} >Manufacture, WholeSaler, Retailer, Agencies</div>
                                    <div className='row mb-5'>
                                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <div className='text-start' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                    Easy Trade
                                                </LinearGradient>
                                            </div>
                                        </div>
                                        <div className='col-xl-8 col-lg-8 col-md-6 col-sm-12'>
                                            <div className='card text-start bg-transparent border-0'>
                                                <p className='pb-3' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >Easy Trade (ERP) is production Planning & Inventory Management Software Solution.</p>
                                                <p className='pb-3' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >Industry covered Apparel & Sarees (Saree, Suit, Lengha, Kurti, and Readymade) Manufacturing & Trading.</p>
                                                <p className='' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >It includes process from Grey to Garment production movements with stage of production.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row align-items-center mb-4'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 mb-4 p-0'>
                                            <img src={UsrFrnd} style={{ width: "100%", objectFit: "cover" }} />
                                        </div>
                                        <div className='col-lg-8 col-md-6 col-sm-12 mb-4'>
                                            <div className='card p-4' style={{ backgroundColor: "#ABB2B9" }}>
                                                <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 500 }} >
                                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                        Why Use ERP System
                                                    </LinearGradient>
                                                </div>
                                                <div className='text-start'>
                                                    <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#000" }} >•	Organizations use Enterprise Resource Planning to manage day-to-day business activities such as accounting, procurement, project management, risk management, compliance, and supply chain operations.</p>
                                                    <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#000" }} >•	An ERP Software helps the administration with real-time information on various aspects of the manufacturing process such as sewing, dyeing, printing, labeling, and packaging to ensure quality control and effective implementation.</p>
                                                    <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#000" }} >•	Easy Trade apparel manufacturing ERP software solution can automatically generate orders, and it offers rich features to simplify business processes.</p>
                                                    <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#000" }} >•	An ERP system allows all customer information to be in one database and streamlines processes to reduce the staff needed to handle transactions.</p>
                                                    <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#000" }} >•	Apparel manufacturing ERP software lets you understand your bottom-line cost while maintaining the right mix of merchandise across the organization.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                                    </div>
                                    <div className='container'>
                                        <div className='row mb-5 animation-element slide-up testimonial' ref={testimonialRef1}>
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                                <div className='text-start' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                        Modules of Software
                                                    </LinearGradient>
                                                </div>
                                            </div>
                                            <div className='col-xl-8 col-lg-8 col-md-6 col-sm-12'>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Job Work</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Production</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Sale/Purchase Management</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Retail Management</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>BI (Reporting)</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Agency</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Mobile App Development</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                                                        <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Website Developmemt</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3 animation-element slide-up testimonial' ref={testimonialRef2}>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={OrdMang} alt="" style={{ width: "50%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Order</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#2E86C1" }}>Management</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={InveMang} alt="" style={{ width: "50%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Inventory</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Management</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={ProdMag} alt="" style={{ width: "40%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Production Process</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Management</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={DsgnStylMang} alt="" style={{ width: "30%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Design/Style</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Image Management</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3 animation-element slide-up testimonial' ref={testimonialRef8}>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={Ebill} alt="" style={{ width: "50%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>GST, E-invoice</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>E-way Bill ITC 04 etc.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={Telly} alt="" style={{ width: "50%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Tally</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Integration</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={MLoc} alt="" style={{ width: "33%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Multi Location</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={SMS} alt="" style={{ width: "31%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>SMS/Email</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Business SMS</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3 animation-element slide-up testimonial' ref={testimonialRef3}>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={JobPlan} alt="" style={{ width: "25%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Job work</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Management</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={Report} alt="" style={{ width: "20%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Reporting &</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Analysis</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={Business} alt="" style={{ width: "49%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Smart Business</p>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Analytic</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={BarQRCode} alt="" style={{ width: "35%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Bar Code/QR Code</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3 animation-element slide-up testimonial' ref={testimonialRef9}>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={ReceivePayment} alt="" style={{ width: "35%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Receive Payment</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={Bilty} alt="" style={{ width: "35%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Bilty Management</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={Catloge} alt="" style={{ width: "63%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Digital catalogue</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                            <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                                <img src={ScrnVarient} alt="" style={{ width: "35%", objectFit: "cover" }} />
                                                <div>
                                                    <p style={{ fontSize: "1.1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#17acff" }}>Screen Varient</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 m-auto'>
                                            <div className='row'>
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                                    <div>
                                                        <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 500, color: "#B2BABB", }}>Intrested in Working</div>
                                                        <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 500, color: "#B2BABB", }}>Together ?</div>
                                                    </div>
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                                    <div>
                                                        <div className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB", }}>&nbsp;</div>
                                                        <div className='row mt-2'>
                                                            <div className='col-lg-8 col-md-6 col-sm-12 mb-3 ms-auto'>
                                                                <div onClick={() => navigate('/Contact')} className='proj-button d-flex align-items-center justify-content-center'>I am Intrested</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-5'>
                                        <div className='col-12 m-auto'>
                                            <div className='card p-5 bg-transparent' style={{ border: "1px solid #ABB2B9 " }} >
                                                <div className='text-start' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                        Benefits of Easy Trade ERP Software Solution
                                                    </LinearGradient>
                                                </div>
                                                <div className='text-start'>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >1. Improving quality throughout the entire manufacturing process. Keep the sales, production and engineering teams in sync and in regular communications. Minimize both wasted time and materials across the production environment.</p>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >2. InventoryManagement : The process of ordering, storing, using, and selling a company's inventory. This includes the management of raw materials, components, and finished products, as well as warehousing and processing of such items.</p>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >3. Easy Management of Customers and Suppliers Informtion system</p>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >4. Sales Increase : Provide excellent customer service to increase sales.In place of always trying to sell to your customers, work on serving them. Consider their needs and how to make things better for them. Providing excellent customer service means going beyond the sales experience to show how much you appreciate them.</p>
                                                    <p className='pb-4' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >Production  Efficiency Improvement :  The first step in improving a production line's efficiency is to evaluate its current state, and below are the key things to consider.</p>
                                                    <p className='pb-2 ps-5' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >• Identifying bottlenecks via observation. </p>
                                                    <p className='pb-2 ps-5' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >• Analyzing workflow to identify inefficiencies</p>
                                                    <p className='pb-2 ps-5' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >• Establishing baseline performance metrics</p>
                                                    <p className='pb-4 ps-5' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >• Gathering data and conducting performance audits</p>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >5. Production planning and control (PPC) in apparel industry refers to the set of activities and processes involved in managing and optimizing</p>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >6. Enhanced reporting involves collecting and analyzing data to create meaningful reports that provide insights into business operations. Enhanced reporting goes beyond basic reporting and provides more in-depth analysis, which can help businesses make informed decisions.</p>
                                                    <p className='pb-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} >7. Order management system is a platform used to track sales, orders, inventory, and fulfillment across business operations.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='text-center pb-5' style={{ fontSize: "2em", fontWeight: 600, }}>
                                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                            Garment's Manufacturing Process Step's
                                        </LinearGradient>
                                    </div>
                                    <div className='col-lg-8 m-auto'>
                                        <img src={ProcImg} style={{ width: "100%", objectFit: "cover" }} />
                                    </div>
                                    <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                                </div>
                            </div>
                            {/* <ETClientList /> */}
                            {/* <ClientNameSlide /> */}
                        </>
                    )}
                </div>
                <div ref={easyPoseRef} style={{ paddingTop: 110 }} >
                    {activeTab === 'easyPose' && (
                        <div className='container'>
                            <div className='text-center' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Easy POS
                                </LinearGradient>
                            </div>
                            <div className='row animation-element slide-up testimonial' ref={testimonialRef4}>
                                <div className='text-center pb-5' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }} > Software Solution Apparel and Sarees</div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                    <div className='text-start' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                            Easy POS
                                        </LinearGradient>
                                    </div>
                                </div>
                                <div className='col-xl-8 col-lg-8 col-md-6 col-sm-12'>
                                    <div className='card text-start bg-transparent border-0'>
                                        <p className='pb-3' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} > Easy Trade (ERP) is production Planning & Inventory Management Software Solution.</p>
                                        <p className='pb-3' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} > Industry covered Apparel & Sarees (Saree, Suit, Lengha, Kurti, and Readymade) Manufacturing & Treading.</p>
                                        <p className='' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }} > It includes process from Grey to Garment production movements with stage of production.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div ref={easyEclubRef} style={{ paddingTop: 110 }} >
                    {activeTab === 'easyEclub' && (
                        <div className='container'>
                            <div className='text-center mb-4' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Easy Club Management Solution
                                </LinearGradient>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <img src={Member} alt="" style={{ width: "30%", }} />
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Member</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <img src={CockFood} alt="" style={{ width: "30%", objectFit: "cover" }} />
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Restaurant</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <img src={barGlass} alt="" style={{ width: "30%", objectFit: "cover" }} />
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Bar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <img src={Games} alt="" style={{ width: "50%", objectFit: "cover" }} />
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Facility</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <img src={clubBook} alt="" style={{ width: "31%", objectFit: "cover" }} />
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Booking</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <img src={eClubReport} alt="" style={{ width: "29%", objectFit: "cover" }} />
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Report</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='text-center mb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        Easy Club Features
                                    </LinearGradient>
                                </div>
                                <div className="card logo-slide-card" style={{ backgroundColor: "#000" }}>
                                    <div className="text-slider">
                                        <div className="text-slider-container">
                                            {texts.map((text, index) => (
                                                <div key={index} >
                                                    <p className='fst-italic' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>{text}</p>
                                                </div>
                                            ))}
                                            {texts.map((text, index) => (
                                                <div key={index} >
                                                    <p className='fst-italic' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>{text}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Reservation</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Front Office</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Kitchen Production</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Room/Lawn Booking</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-2' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>POS for Bar/Restaurant/Coffee Bar/Takeaway</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card p-4' style={{ backgroundColor: "#111212", width:"100%" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Sport/Gym/Swimming/Health Club & Facility Center</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Back Office  Club Management</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
                                    <div className='card align-items-center p-4' style={{ backgroundColor: "#111212" }}>
                                        <div>
                                            <p style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Financial Data Transfer to Tally</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'>
                                    <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                        <div className='text-start' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                Easy Club Restaurant Features :-
                                            </LinearGradient>
                                        </div>
                                        <div className='p-4' >
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Provide integrated food and material management.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• KOT Preparation.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Bill Generation.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Table wise/Waiter wise/Member wise sale/ Item wise daily sales.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Cashier Collection Detail.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Multiple Payment mode sattlement.</p>
                                            <p className='text-start ps-5' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>(Cash/Credit/Credit Card/Transfer to member A/C)</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Cards can run multiple applications. (Identification, Club Card, E-Purse, Loyalty, etc.).</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Deleted and Unsettled Bills.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3'>
                                    <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                        <div className='text-start' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                Easy Club Member Modules :-
                                            </LinearGradient>
                                        </div>
                                        <div className='p-4' >
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Member Admission (Membership type wise).</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Record member's personal details.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Record family member information.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Member's monthly Subscription & Billing.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• SMART CARD generation and its interface with card reader.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Facilities availed by a member.</p>
                                            <p className='text-start ps-5' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>(Swimming Pool/Cards/Pool/Other Sports)</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Member Tracking.</p>
                                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Member Outstanding.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-8 m-auto'>
                                    <p className='text-center' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}> Get a complete High Tech look with smart access control & backward integration of resources</p>
                                </div>
                            </div>
                            <div className='row mb-5 animation-element slide-up testimonial' ref={testimonialRef6}>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                        <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                Advantages of Smart Card
                                            </LinearGradient>
                                        </div>
                                        <div className='p-4' >
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• High Security.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• High Reliability.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Easy Forward & Backward Integration.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Multi-Platform. </p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Multi-Applications.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Multi-Database.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Cards can run multiple applications. (Identification, Club Card, E-Purse, Loyalty, etc.).</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                        <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                Advantages to the Organisation
                                            </LinearGradient>
                                        </div>
                                        <div className='p-4' >
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• High Efficiency.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Easy Operational Management.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Improves member satisfaction.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Best utility services & facilities provider.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Reduced Operational Cost.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Reduce back office staff.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Increase in revenue by efficient. </p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Controls unauthorised & unwanted People. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3'>
                                    <div className='card p-4' style={{ backgroundColor: "#111212" }}>
                                        <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600 }} >
                                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                                Advantage to the Members
                                            </LinearGradient>
                                        </div>
                                        <div className='p-4' >
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Cash less transactions.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Secured & accurate billing.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Guest transaction can be controlled.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Members kiosk.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Personalised services.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Easy booking and access to facilities.</p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Hassle free environment. </p>
                                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>• Member can get complete history of events & transactions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 m-auto'>
                                    <div className='row'>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <div>
                                                <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 500, color: "#B2BABB", }}>Intrested in Working</div>
                                                <div className='text-start' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 500, color: "#B2BABB", }}>Together ?</div>
                                            </div>
                                        </div>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <div>
                                                <div className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB", }}>&nbsp;</div>
                                                <div className='row mt-2'>
                                                    <div className='col-lg-8 col-md-6 col-sm-12 mb-3 ms-auto'>
                                                        <div onClick={() => navigate('/Contact')} className='proj-button d-flex align-items-center justify-content-center'>I am Intrested</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    )
}
