import React, { useEffect, useRef, useState } from 'react';
import { LinearGradient } from 'react-text-gradients';
import { GoMail } from "react-icons/go";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import emailjs from 'emailjs-com';
import nameLogo from '../images/nameLogo.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Contact() {

    const initialFormData = {
        to_name: 'Concept Tek Solution',
        name: '',
        email: '',
        mobNumber: '',
        enquiry: '',
        bustype: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        // Initialize emailjs
        emailjs.init('bwI_848cKperf_vu1');
    }, []);

    const testimonialRef1 = useRef(null);
    const testimonialRef2 = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const windowTopPosition = window.scrollY;
            const windowBottomPosition = windowTopPosition + windowHeight;

            const elements = [testimonialRef1.current, testimonialRef2.current];

            elements.forEach((element) => {
                if (!element) return;

                const elementHeight = element.offsetHeight;
                const elementTopPosition = element.offsetTop;
                const elementBottomPosition = elementTopPosition + elementHeight;

                if (
                    elementBottomPosition >= windowTopPosition &&
                    elementTopPosition <= windowBottomPosition
                ) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    useEffect(() => {
        scrollToTop();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'mobNumber') {
            const isValid = /^[0-9]*$/.test(value) && value.length <= 10;

            if (isValid || value === '') {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.mobNumber.length < 10) {
            alert('Please enter a valid 10-digit mobile number.');
            return;
        }
        emailjs.send('service_wz861id', 'template_2xjhaij', formData, 'bwI_848cKperf_vu1')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                handleClearForm();
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again later.');
            });
    };

    const handleClearForm = () => {
        setFormData(initialFormData);
    };

    return (
        <div className='mt-5'>
            <div className='container pt-5'>
                <p className='text-center' style={{ fontSize: "4em", fontFamily: "Noto Sans", fontWeight: 400, }}>
                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                        Contact Us
                    </LinearGradient>
                </p>
                <div className='row mb-5'>
                    <div className='col-md-4 col-sm-12 m-auto'>
                        <p className='text-center' style={{ fontSize: "14px", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff", letterSpacing: 2 }}>
                            Got a request, feedback, or enquiry? Want to start a conversation about your project? Tell us how we can help and we'll get in touch as soon as possible.
                        </p>
                        <div className='d-flex justify-content-center align-items-center' >
                            <GoMail className='' style={{ color: "#B2BABB" }} />
                            <p className='text-center ps-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>conceptteksolution@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-10 col-md-8 col-sm-12 m-auto'>
                        <div className='card border-0 py-5 animation-element slide-up testimonial' ref={testimonialRef1} style={{ width: "100%", background: "linear-gradient(to top, #56657380 3%, #21212150 70%)" }} >
                            <div className='row pt-5 px-4'>
                                <div className='col-lg-5 col-md-6 col-sm-12'>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            className="input-box shadow-none border-0 p-3 mb-4"
                                            type="text"
                                            placeholder="Company Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            className="input-box shadow-none border-0 p-3 mb-4"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            className="input-box shadow-none border-0 p-3 mb-4"
                                            type="tel"
                                            placeholder="Mob Number"
                                            name="mobNumber"
                                            value={formData.mobNumber}
                                            onChange={handleChange}
                                            required
                                            maxLength={10}
                                        />
                                        <select
                                            className="form-select input-box shadow-none border-0 p-3 mb-4"
                                            aria-label="Default select example"
                                            name="enquiry"
                                            value={formData.enquiry}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="#" disabled>---Select---</option>
                                            <option value="easytrade">Easy Trade</option>
                                            <option value="easyPOS">EasyPOS</option>
                                            <option value="easyclub">Easy Club</option>
                                        </select>
                                        <select
                                            className="form-select input-box shadow-none border-0 p-3 mb-4"
                                            aria-label="Default select example"
                                            name="bustype"
                                            value={formData.bustype}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="#" disabled>---Select---</option>
                                            <option value="manufacturing">Manufacturing</option>
                                            <option value="wholesaler">Wholesaler</option>
                                            <option value="retailer">Retailer</option>
                                            <option value="agency">Agency</option>
                                            <option value="other">Other</option>
                                        </select>
                                        <div className="form-floating">
                                            <textarea
                                                className="input-box shadow-none border-0 p-3 mb-5"
                                                placeholder="Leave a comment here"
                                                id="floatingTextarea"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                        </div>
                                        <button type="submit" className='proj-button d-flex align-items-center justify-content-center'>Submit</button>
                                    </form>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 ms-auto'>
                                    <div className='card border-0 rounded-0'>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14243.138802517258!2d75.7952727!3d26.8149832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dcb5e71ad9605%3A0x3cff84130ea0fe35!2sConcept%20Tek%20Solution!5e0!3m2!1sen!2sin!4v1718176823924!5m2!1sen!2sin" width="100%" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className='card text-start border-0 rounded-0 bg-transparent mt-5'>
                                        <img className='mb-2' src={nameLogo} style={{ width: "60%" }} />
                                        <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 200, color: "#D0D3D4 ", letterSpacing: 2 }}>36-A, Flat No. LG-1, Rameshwaram Colony</p>
                                        <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 200, color: "#D0D3D4 ", letterSpacing: 2 }}>Opp  Aapni Dhani Restaurant, Sitabari</p>
                                        <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 200, color: "#D0D3D4 ", letterSpacing: 2 }}>Tonk Road, Jaipur – 302019, Rajasthan, India</p>
                                        <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 200, color: "#D0D3D4 ", letterSpacing: 2 }}>Email: conceptteksolution@gmail.com</p>
                                        <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 200, color: "#D0D3D4 ", letterSpacing: 2 }}>+91 8239500123, +91 8239600123</p>
                                        <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 200, color: "#D0D3D4 ", letterSpacing: 2 }}>Time: Mon - Fri(11:00 AM - 06:00 PM)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    );
}
