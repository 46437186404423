import React, { useEffect } from 'react';
import { LinearGradient } from 'react-text-gradients';
import { IoArrowUpCircleOutline } from "react-icons/io5";

export default function Infringement() {

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className='container mt-5'>
                <div className='row pt-5'>
                    <div className='col-md-12 pt-5'>
                        <p className='text-start' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                IP Infringement Policy
                            </LinearGradient>
                        </p>
                        <p className='text-start pb-2 text-decoration-underline' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Notice and Procedure for Making Claims of Right Infringements</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>We respect third parties Intellectual Property Rights. If your rights are being infringed, you notify us by submitting the Notice Form attached to this policy.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Upon receipt of a Notice Form we may take certain actions, such as informing the users about the infringing content or removing information without any admission as to liability and without prejudice to any rights, remedies or defenses, all of which are expressly reserved. Furthermore, in submitting a Notice Form, you grant to conceptteksolution the right to use, reproduce, modify, adapt, publish, translate, create derivative works from, and display its content throughout the world in any media. This includes forwarding the Notice Form to the parties involved in the provision of the allegedly infringing content. You agree to indemnify conceptteksolution for all claims brought by a third party against conceptteksolution arising out of or in connection with the submission of a Notice Form.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Note on Third Party User Listings: Please keep in mind that Third Party User listings are merely hosted on conceptteksolution and are posted solely by the Third Party Users who may be contacted via their "User Information" page, accessible from any of their listings.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Important Warning: giving false, misleading or inaccurate information in the Notice Form to conceptteksolution may result in civil and/or criminal liability. You should contact a legal advisor should you have any questions.</p>
                        <p className='text-start pb-2 text-decoration-underline' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Notice Form:</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>If you believe that your rights are being violated by an information on the conceptteksolution, you may fill out and submit the Notice Form (below). This form needs to be signed can be sent via E-mail PDF to the Grievance Officer:
                            e-mail: concepttekjpr@gmail.com
                            Subject Line: Notice of Infringement
                            (We will accept a signed PDF via e-mail with the subject line "Notice of Infringement").
                            Please fill out the Notice form using the corresponding numbered paragraphs to frame your communication.
                        </p>
                        <p className='text-start pb-2 text-decoration-underline' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Notice Form</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>I, [INSERT FULL NAME AND TITLE] of [COMPANY NAME, IF APPLICABLE], state as follows:</p>
                        <p className='text-start pb-2 text-decoration-underline' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>1.	Contact Information</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>(a) Your and/ or your company's name, address, telephone number and contact email address; </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>(b) The contact email address and/or name which we will provide to Third Party Sellers (if relevant) so they may contact you to resolve any issues regarding your notification to us. If you do not provide a separate contact email, you authorize us to use the contact information you provide in while registering at conceptteksolution.</p>
                        <p className='text-start pb-2 text-decoration-underline' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>2.	Listing's details and Allegation of Infringed Right:</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>(a) The listing's URL and detailed description of the information that you claim is infringing your rights is located on conceptteksolution Platform; if regarding a Third Party User listing please also provide the name used to identify the User on the Platform. </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>(b) A description of your intellectual property right(s) that you claim has/have been infringed. Please provide the copy of Trademark Certificate / Copyright Registration Certificate / Patent Registration Certificate.  [Please also provide as to how you have arrived in determining that third party Intellectual Property Rights have been infringed]</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>3.	Include the following statement: "I have a good faith belief that the portion of the listing(s) described above violate(s) the intellectual property rights owned by the intellectual property owner or its agent, nor is such use otherwise permissible under law." [Please provide a copy of authorization Certificate in favor of applicant]</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>4.	Include the following statement: "I represent that the information in this notification is true and correct and that I am the intellectual property owner or authorised to act on behalf of the intellectual property owner for the rights described above."</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>5.	Sign the Notice Form.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}></p>
                    </div>
                </div>
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    )
}
