
import React from 'react';
import Slider from 'react-slick';
import './TestimonialCss.css';
import { FaQuoteLeft } from "react-icons/fa";

import Adhira from '../images/clientLogos/aadira logo.jpg';
import AClogo from '../images/clientLogos/AC Logo.png';
import Aratari from '../images/clientLogos/Aari Tari.png';
import Anenex from '../images/clientLogos/Annex Creation.jpeg';
import Benaaz from '../images/clientLogos/Benaaz Collection.png';
import Bhagwati from "../images/clientLogos/Bhagwati Fashions Pvt. Ltd..png";
import Choteylal from '../images/clientLogos/Chhoteylal Nemichand Sogani.png';
import Daaman from '../images/clientLogos/Daaman.jpg';
import DevTextile from '../images/clientLogos/Dev Textiles.png';
import DsgnWorld from '../images/clientLogos/Design world.jpg';
import Flo from '../images/clientLogos/Flo.jpeg';
import FabStyle from '../images/clientLogos/fabstyle.png';
import Ganpati from '../images/clientLogos/Ganpati Exclusive Designer Sarees Pvt. Ltd..png';
import GlobalInd from '../images/clientLogos/globalIndia.png';
import Heerawat from '../images/clientLogos/Hirawat.png';
import Kamya from '../images/clientLogos/Kamya.jpg';
import KkSaree from '../images/clientLogos/KK Saree.png';
import Madhuri from '../images/clientLogos/Madhuri.png';
import MahalxmiSari from '../images/clientLogos/Mahalaxmi Saree Emporium.png';
import MahalaxmiTax from '../images/clientLogos/MAHALAXMI TEX FAB_page-0002.jpg';
import MaharaniSari from '../images/clientLogos/Maharani Saree Emporium.png';
import ManishDsgnr from '../images/clientLogos/Manish Designers Pvt. Ltd..png';
import MeghDoot from '../images/clientLogos/Meghdoot.png';
import MSBandhani from '../images/clientLogos/MS Bandhani.png';
import Navratan from '../images/clientLogos/Navratan Bandhani.png';
import Nikhar from '../images/clientLogos/Nikhaar Collection.png';
import Noya from '../images/clientLogos/Noya.png';
import Padmawati from '../images/clientLogos/Padmavati Sarees.png';
import RadheKrishn from '../images/clientLogos/Radhe Kirshna Silks, Bangalore.png';
import Rajvesh from '../images/clientLogos/Rajvesh.png';
import RAMAS from '../images/clientLogos/RAMAS.jpeg';
import RanasLegacy from '../images/clientLogos/Ranas Legacy.png';
import Ranas from '../images/clientLogos/Ranas.png';
import Roop from '../images/clientLogos/Roop Fashion.png';
import RRFashion from '../images/clientLogos/RR Fashions.png';
import RSFashin from '../images/clientLogos/RS Fashion.png';
import Sampat from '../images/clientLogos/SAMPATSACHIN.jpg';
import Shivani from '../images/clientLogos/SHIVANI CREATION.jpeg';
import ShriKanha from '../images/clientLogos/Shree Kanha Fashions.png';
import ShriKrishnam from '../images/clientLogos/SHRI KRISHNAM.jpg';
import ShriNarayan from '../images/clientLogos/Shri Narayan Collection.png';
import SKSari from '../images/clientLogos/SK Saree Palace.png';
import SREnterprises from '../images/clientLogos/SR Enterprises.png';
import Surekha from '../images/clientLogos/Surekha Delhi.jpg';
import Vimal from '../images/clientLogos/Vimal Creations.png';
import Aamori from '../images/clientLogos/AAMORI.png';
import Akshay from '../images/clientLogos/AKSHAY AGENCY.png';
import Albeli from '../images/clientLogos/ALBELI.png';
import Amit from '../images/clientLogos/AMIT SAREE.png';
import Bhuramal from '../images/clientLogos/BHURAMAL.jpg';
import Ethinic from '../images/clientLogos/ETHNIC EDGE.png';
import FabStory from '../images/clientLogos/FAB STORY.jpg';
import Ikkat from '../images/clientLogos/IKKAT.jpg';
import Islie from '../images/clientLogos/ISLIE.png';
import Shakshi from '../images/clientLogos/SAKSHI.jpg';
import Silaai from '../images/clientLogos/SILAAI.png';
import Swaraj from '../images/clientLogos/SWARAJ.jpg';
import Tina from '../images/clientLogos/TINAFASHION.jpg';

const testimonials = [
  { id: 1, imgUrl: Adhira, },
  { id: 2, imgUrl: AClogo, },
  { id: 3, imgUrl: Aratari, },
  { id: 4, imgUrl: Anenex, },
  { id: 5, imgUrl: Benaaz, },
  { id: 6, imgUrl: Bhagwati, },
  { id: 7, imgUrl: Choteylal, },
  { id: 8, imgUrl: Daaman, },
  { id: 9, imgUrl: DevTextile, },
  { id: 10, imgUrl: DsgnWorld, },
  { id: 11, imgUrl: Flo, },
  { id: 12, imgUrl: FabStyle, },
  { id: 13, imgUrl: Ganpati, },
  { id: 14, imgUrl: GlobalInd, },
  { id: 15, imgUrl: Heerawat, },
  { id: 16, imgUrl: Kamya, },
  { id: 17, imgUrl: KkSaree, },
  { id: 18, imgUrl: Madhuri, },
  { id: 19, imgUrl: MahalxmiSari, },
  { id: 20, imgUrl: MahalaxmiTax, },
  { id: 21, imgUrl: MaharaniSari, },
  { id: 22, imgUrl: ManishDsgnr, },
  { id: 23, imgUrl: MeghDoot, },
  { id: 24, imgUrl: MSBandhani, },
  { id: 25, imgUrl: Navratan, },
  { id: 26, imgUrl: Nikhar, },
  { id: 27, imgUrl: Noya, },
  { id: 28, imgUrl: Padmawati, },
  { id: 29, imgUrl: RadheKrishn, },
  { id: 30, imgUrl: Rajvesh, },
  { id: 31, imgUrl: RAMAS, },
  { id: 32, imgUrl: RanasLegacy, },
  { id: 33, imgUrl: Ranas, },
  { id: 34, imgUrl: Roop, },
  { id: 35, imgUrl: RRFashion, },
  { id: 36, imgUrl: RSFashin, },
  { id: 37, imgUrl: Sampat, },
  { id: 38, imgUrl: Shivani, },
  { id: 39, imgUrl: ShriKanha, },
  { id: 40, imgUrl: ShriKrishnam, },
  { id: 41, imgUrl: ShriNarayan, },
  { id: 42, imgUrl: SKSari, },
  { id: 43, imgUrl: SREnterprises, },
  { id: 44, imgUrl: Surekha, },
  { id: 45, imgUrl: Vimal, },
  { id: 46, imgUrl: Aamori, },
  { id: 47, imgUrl: Akshay, },
  { id: 48, imgUrl: Albeli, },
  { id: 49, imgUrl: Amit, },
  { id: 50, imgUrl: Bhuramal, },
  { id: 51, imgUrl: Ethinic, },
  { id: 52, imgUrl: FabStory, },
  { id: 53, imgUrl: Ikkat, },
  { id: 54, imgUrl: Islie, },
  { id: 55, imgUrl: Shakshi, },
  { id: 56, imgUrl: Silaai, },
  { id: 57, imgUrl: Swaraj, },
  { id: 58, imgUrl: Tina, },
];

const TestimonialSlider = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 600,
    draggable: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <main>
      {/* <section> */}
      <div className="testimonial">
        <div className="container">
          <div className="testimonial__inner">
            <Slider {...settings}>
              {testimonials.map(testimonial => (
                <div key={testimonial.id} className="testimonial-slide">
                  {/* <div className=""> */}
                  <div className="">
                    <div className="testimonial_box-top p-3 rounded-1" style={{ backgroundColor: "#fff", width: "100%", height: '30vh' }}>
                      {/* <div className="p-0 border-0" style={{}}> */}
                        <img src={testimonial.imgUrl} alt="profile" style={{ width: '80%', objectFit: "cover", borderRadius: 0, border: "none" }} />
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                // </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* </section> */}
    </main>
  );
};

export default TestimonialSlider;
