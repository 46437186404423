import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Home from './Component/Home';
import Product from './Component/Product';
import Company from './Component/Company';
import Contact from './Component/Contact';
import Policy from './Component/Policy';
import TermOfUse from './Component/TermOfUse';
import Infringement from './Component/Infringement';
import Service from './Component/Service';
import Clients from './Component/Clients';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/Home' element={<Home />} />
          <Route path='/Product' element={<Product />} />
          <Route path='/Company' element={<Company />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Policy' element={<Policy />} />
          <Route path='/TermOfUse' element={<TermOfUse />} />
          <Route path='/Infringement' element={<Infringement />} />
          <Route path='/Service' element={<Service />} />
          <Route path='/Clients' element={<Clients />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;