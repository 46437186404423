import React, { useEffect } from 'react';
import { LinearGradient } from 'react-text-gradients';
import { IoArrowUpCircleOutline } from "react-icons/io5";

export default function Policy() {

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className='container mt-5'>
                <div className='row pt-5'>
                    <div className='col-md-12 pt-5'>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>PRIVACY POLICY</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>conceptteksolution.com and its E-COMMERCE application platform (each a “Platform”) is a platform which allows the users to communicate with each other directly either through direct messaging or through the E-COMMERCE application itself without any kind of intervention from Conceptteksolution. Conceptteksolution recognizes the importance of privacy as well as the importance of maintaining the confidentiality of personal information. This Privacy Policy applies to all products and services provided by Conceptteksolution and sets out how Conceptteksolution may collect, use and disclose information in relation to Users of the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>User may use Conceptteksolution’s services and products via a Computer, Mobile, Tablet etc. device either through E-COMMERCE applications or E- COMMERCE optimized websites. This Privacy Policy also applies to such use of Conceptteksolution’s services and products.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>All capitalized terms not defined in this document shall have the meanings as described to them in the Terms of Use of the Platform, which can be found here. Contracting entity shall be The TRUSTED TEX DEAL LLP (herein after referred to as ‘Conceptteksolution’ or ‘us’ or ‘our’)</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>COLLECTION OF INFORMATION</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>User(s) privacy is important to Conceptteksolution and Conceptteksolution have taken steps to ensure that Conceptteksolution do not collect more information from User than is necessary for Conceptteksolution to provide User(s) with Conceptteksolution’s services and to protect User(s) account.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Information including, but not limited to, User name, address, phone number, fax number, email address, gender, date and/or year of birth and user preferences ("Registration Information") may be collected at the time of User registration on the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>In connection with any communication or transaction and payment services or any other services that you may avail using the Platform, information, including but not limited to, bank account numbers, billing and delivery information, credit/debit card numbers and expiration dates and tracking information from cheques or money orders ("Account Information") may be collected, among other things, to facilitate the sale and purchase as well as the settlement of purchase price of the products or services transacted on or procured through the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Conceptteksolution record and retain details of Users’ activities on the Platform. Information relating to communication or transactions including, but not limited to, the types and specifications of the goods, pricing and delivery information, any dispute records and any information disclosed in any communication forum provided by us and/or other affiliated companies of Conceptteksolution (“Activities Information”) may be collected as and when the communication and / or transactions are conducted through the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Conceptteksolution record and retain records of Users’ browsing or buying activities on Platform including but not limited to IP addresses, browsing patterns and User behavioral patterns. In addition, we gather statistical information about the Platform and visitors to the Platform including, but not limited to, IP addresses, browser software, operating system, software and hardware attributes, pages viewed, number of sessions and unique visitors (together "Browsing Information").</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Registration Information, Account Information, Activities Information, and Browsing Information are collectively referred to as Personal Data.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>It is mandatory for Users of the Platform to provide certain categories of Personal Data (as specified at the time of collection). In the event that Users do not provide any or sufficient Personal Data marked as mandatory, Conceptteksolution may not be able to complete the registration process or provide such Users with Conceptteksolution’s products or services.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>USE OF PERSONAL DATA</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>If you provide any Personal Data to Conceptteksolution, you are deemed to have authorized Conceptteksolution to collect, retain and use that Personal Data for the following purposes:</p>
                        <p className='text-start' style={{ fontSize: "1.3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Verification of User’s identity;
                            </LinearGradient>
                        </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>processing User’s registration as a user, providing User with a log-in ID for the Platform and maintaining and managing User’s registration;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>providing User with customer service and responding to User(s) queries, feedback, claims or disputes;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>to facilitate communication between Users on the Platform and / or processing Users transactions on the Platform;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>performing research or statistical analysis in order to improve the content and layout of the Platform, to improve Conceptteksolution’s product offerings and services and for marketing and promotional purposes;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>subject to applicable law, Conceptteksolution (including our affiliated companies and their designated Service Providers may use User’s name, phone number, residential address, email address, fax number and other data ("Marketing Data") to provide notices, surveys, product alerts, communications and other marketing materials to User(s) relating to products and services offered by Conceptteksolution or Conceptteksolution’s affiliated companies;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>if User voluntarily submit User information or other information to the Platform for publication on the Platform through the publishing tools, then Users are deemed to have given consent to the publication of such information on the Platform; and</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>making such disclosures as may be required for any of the above purposes or as required by law, regulations and guidelines or in respect of any investigations, claims or potential claims brought on or against us or against third parties.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>DISCLOSURE OF PERSONAL DATA</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>User further agrees that Conceptteksolution may disclose and transfer User’s Personal Data to third party service providers (including but not limited to data entry, database management, promotions, products and services alerts, delivery services, payment extension services, authentication and verification services and logistics services) ("Service Providers"). These Service Providers are under a duty of confidentiality to Conceptteksolution and are only permitted to use Users Personal Data in connection with the purposes specified in clause 2 herein above.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>User(s) agree that Conceptteksolution may disclose and transfer User(s) Personal Data to Conceptteksolution’s affiliated companies and/or their designated Service Providers.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>When necessary Conceptteksolution may also disclose and transfer User’s Personal Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Any Personal Data supplied by User will be retained by Conceptteksolution and will be accessible by our employees, any Service Providers engaged by Conceptteksolution and third parties referred to in clause 3 herein, for or in relation to any of the purposes stated in Clause 2 herein above.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>All voluntary information may be made publicly available on the Platform and therefore accessible by any internet user. Any voluntary information that User disclose to Conceptteksolution becomes public information and User relinquish any proprietary rights (including but not limited to the rights of confidentiality and copyright) in such information. User should exercise caution when deciding to include personal or proprietary information in the voluntary information that User submit to Conceptteksolution.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Conceptteksolution may share User’s Personal Data with third parties, including without limitation, banks, financial institutions, credit agencies, or vendors to enable such third parties to offer their products or services to such Users. While Conceptteksolution shall endeavor to have in place internal procedures to keep User’s Personal Data secure from intruders, there is no guarantee that such measures/procedures can eliminate all of the risks of theft, loss or misuse.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Conceptteksolution may establish relationships with other parties and websites to offer User the benefit of products and services which Conceptteksolution does not offer. Conceptteksolution may offer you access to these other parties and/or their websites. This Privacy Policy does not apply to the products and services enabled or facilitated by such third parties. The privacy policies of those other parties may differ from Conceptteksolution, and Conceptteksolution has no control over the information that User may submit to those third parties. User should read the relevant privacy policy for those third parties before responding to and availing any offers, products or services advertised or provided by those third parties.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>RIGHT TO UPDATE PERSONAL DATA</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Under the applicable laws, User have the right of access to personal information held by Conceptteksolution and to request updating / correcting the information.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>COOKIES</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Conceptteksolution uses "cookies" to store specific information about User and track User(s) visits to the Sites. A "cookie" is a small amount of data that is sent to User’s browser and stored on User’s device. If User do not de-activate or erase the cookie, each time User uses the same device to access the Platform, our services will be notified of User visit to the Platform and in turn Conceptteksolution may have knowledge of User visit and the pattern of User’s usage.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Generally, Conceptteksolution use cookies to identify User and enable Conceptteksolution to i) access User’s Registration Information or Account Information so User do not have to re-enter it; ii) gather statistical information about usage by Users; iii) research visiting patterns and help target advertisements based on User interests; iv) assist Conceptteksolution’s partners to track User visits to the Platform and process orders; and v) track progress and participation on the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>User can determine if and how a cookie will be accepted by configuring the browser which is installed in User’s device. If User choose, User can change those configurations. If User reject all cookies by choosing the cookie-disabling function, User may be required to re-enter information on the Platform more often and certain features of the Platform may be unavailable.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>MINORS</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>The Platform and its contents are not targeted to minors (those under the age of 18). However, Conceptteksolution have no way of distinguishing the age of individuals who access our Platform. If a minor has provided Conceptteksolution with personal information without parental or guardian consent, the parent or guardian should contact Conceptteksolution’s Legal Department at the address set out in clause 9 below to remove the information.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>SECURITY MEASURES</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Conceptteksolution employ commercially reasonable security methods to prevent unauthorized access to the Platform, to maintain data accuracy and to ensure the correct use of the information Conceptteksolution hold. No data transmission over the internet or any wireless network can be guaranteed to be perfectly secure. As a result, while Conceptteksolution try to protect the information Conceptteksolution hold, Conceptteksolution cannot guarantee the security of any information User transmit to Conceptteksolution and Users do so at their own risk.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>CHANGES TO THIS PRIVACY POLICY</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Any changes to this Privacy Policy will be communicated by us posting an amended and restated Privacy Policy on the Platform. Once posted on the Platform the new Privacy Policy will be effective immediately. User agree that any information Conceptteksolution hold about User (as described in this Privacy Policy and whether or not collected prior to or after the new Privacy Policy became effective) will be governed by the latest version of the Privacy Policy.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>CHANGES TO THIS PRIVACY POLICY</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 ms-auto'>
                        <div className='card text-start bg-transparent'>
                            <p className='' style={{ fontSize: "1.3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Avinash Jain
                                </LinearGradient>
                            </p>
                            <p className='t' style={{ fontSize: "1.3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution
                                </LinearGradient>
                            </p>
                            <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>+91 8239500123, +91 8239600123</p>
                            <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 300, color: "#fff" }}>Email: conceptteksolution@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    )
}
