import React, { useEffect } from 'react';
import { LinearGradient } from 'react-text-gradients';
import { FaCode, FaPaintBrush, FaDatabase, FaRegHandshake } from "react-icons/fa";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { MdDeveloperMode } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import './Service.css'

export default function Service() {

    const data = [
        {
            title: 'Apps Designs',
            desc: 'With a commitment to the latest technological trends, our team designs and develops user-friendly web and mobile apps that capture user interest and foster meaningful engagement.',
            icon: FaCode
        },
        {
            title: 'Web Designing',
            desc: 'Our web design services emphasize both form and function, resulting in beautiful, high-performance websites that meet your business objectives and exceed user expectations.',
            icon: FaPaintBrush
        },
        {
            title: 'Database Management',
            desc: 'Focused on innovation and trustworthiness, our experts provide sophisticated database management services that refine data handling and support your business strategies.',
            icon: FaDatabase
        },
        {
            title: 'Enterprise Software Development',
            desc: 'We expertise in enterprise software development ensures the creation of powerful, scalable solutions that address critical business challenges and drive long-term growth.',
            icon: MdDeveloperMode
        },
        {
            title: 'IT Consulting',
            desc: 'We offer strategic IT consulting to help you navigate complex technology challenges, implement innovative solutions, and drive sustainable growth for your organization.',
            icon: FaRegHandshake
        },
        {
            title: 'UI/UX Design',
            desc: 'Through thoughtful design and usability testing, we create digital experiences that are not only beautiful but also optimized for usability and accessibility.',
            icon: GrWorkshop
        },
    ]

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className='container mt-5'>
                <div className='row py-5'>
                    <div className='text-center mt-5 pb-5' style={{ fontSize: "2.5em", fontWeight: 600, color:"#21618c" }}>
                        {/* <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}> */}
                            Our Services
                        {/* </LinearGradient> */}
                    </div>
                    <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 m-auto'>
                        <div className='row'>
                            {
                                data.map((item, index) => {
                                    const IconComponent = item.icon;
                                    return (
                                        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
                                            <div key={index} className='card p-5 align-items-center card-service' >
                                                <IconComponent className='service-icon pb-3' />
                                                <p className='pb-2 title-text' style={{ fontSize:'18px', fontWeight:600 }}>{item.title}</p>
                                                <p className='title-desc' style={{ fontSize:'14px', fontWeight:500 }}>{item.desc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    )
}
