import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

import logo from '../images/newlogo.png';

export default function Footer() {

    const navigate = useNavigate();

    const initialFormData = {
        email: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            // Apply email validation here if needed
            // Example: Basic email format validation
            const isValidEmail = /\S+@\S+\.\S+/.test(value);

            if (isValidEmail || value === '') {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_wz861id', 'template_2xjhaij', formData, 'bwI_848cKperf_vu1')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                handleClearForm();
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again later.');
            });
    };

    const handleClearForm = () => {
        setFormData(initialFormData);
    };

    return (
        <div>
            <div className='footer mt-5 p-0'>
                <footer class="text-center text-lg-start" style={{ backgroundColor: '#1D1D1F' }}>
                    <div class="container-fluid px-5">
                        <div class="row d-flex align-items-center justify-content-between py-3">
                            <div class="col-lg-2 col-md-12 mb-2 mb-md-0">
                                <img class="" src={logo} alt="" style={{ width: "100%" }} />
                                <div>
                                    <div className='d-flex mt-3' style={{ gap: "20px" }}>
                                        <div className='justify-content-center align-items-center d-flex rounded-1 linkedIn-icon' style={{ width: 30, height: 30, cursor: "pointer" }} ><FaLinkedin /> </div>
                                        <div onClick={() => window.open('https://www.facebook.com/Conceptteksolution/', '_blank')} className='justify-content-center align-items-center d-flex rounded-1 facebook-icon' style={{ width: 30, height: 30, cursor: "pointer" }} ><FaFacebookSquare /> </div>
                                        <div onClick={() => window.open('https://www.instagram.com/concept_tek_solution/?igshid=YmMyMTA2M2Y%3D', '_blank')} className='justify-content-center align-items-center d-flex rounded-1 insta-icon' style={{ width: 30, height: 30, cursor: "pointer" }} ><GrInstagram /> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mb-2 mb-md-0 ">
                                <p class="text-start" style={{ color: "#B2BABB", fontWeight: 400, fontSize: "14px" }}>Subscribe to our Tech Newsletter</p>
                                <div class=" mb-3">
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            className="input-box shadow-none border-0 p-3 mb-4"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            defaultValue={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <button type="submit" className='lern-btn' style={{ width: "50%" }}>Subscribe</button>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 mb-3">
                                <h5 class="text-uppercase" style={{ color: "#5DADE2" }} >SITEMAP</h5>
                                <div className='nav-links-footer' onClick={() => navigate('/Home')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Home</div>
                                <div className='nav-links-footer' onClick={() => navigate('/Product')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Products</div>
                                <div className='nav-links-footer' onClick={() => navigate('/Service')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Services</div>
                                <div className='nav-links-footer' onClick={() => navigate('/Clients')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Clients</div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <h5 class="text-uppercase" style={{ color: "#5DADE2" }} >SUPPORT</h5>
                                <div className='nav-links-footer' onClick={() => navigate('/Company')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>About Us</div>
                                <div className='nav-links-footer' onClick={() => navigate('/Contact')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Contact</div>
                                <div className='nav-links-footer' style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>&nbsp;</div>
                            </div>
                            <div class="col-lg-2 col-md-12">
                                <h5 class="text-uppercase" style={{ color: "#5DADE2" }} >LEGAL</h5>
                                {/* <div className='nav-links-footer' onClick={()=> navigate('/TermOfUse')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Term of Use</div> */}
                                <div className='nav-links-footer' onClick={() => navigate('/Policy')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Privacy Policy</div>
                                <div className='nav-links-footer' onClick={() => navigate('/Infringement')} style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>Infringement</div>
                                <div className='nav-links-footer' style={{ cursor: "pointer", fontSize: 14, fontWeight: 400, color: "#B2BABB" }}>&nbsp;</div>
                            </div>
                        </div>
                        <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                        <div className='d-flex justify-content-between align-items-center py-3' style={{}} >
                            <div class="" style={{ fontSize: 10, fontWeight: 400, cursor: "pointer", color: "#B2BABB" }}>
                                Copyright © 2024 EasyTrade • All rights reserved
                            </div>
                            <div class="" style={{ fontSize: 10, fontWeight: 400, cursor: "pointer", color: "#B2BABB" }}>
                                India
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <ToastContainer />
        </div>
    )
}
