import React from 'react';

export default function ClientNameSlide() {

    const texts = [
        'Aura', 'Brijwasi', 'Darshan Fashion', 'Heena Enterprises', 'Hunny Sarees', 'Kanisha Fashion', 'Katta Impex', 'Malpani', 'M K Creation', 'Maharani Fabric', 'Rubi Creation', 'Ramgopal, Rambalabh', 'Roop Sons', 'Roop Fab Tex', 'Riya Sarees', 'Shubhangam', 'Sheela Designer Sarees', 'Urban Basis', 'Vedganga', 'Outfit Outlet',
    ];

    return (
        <div>
            <div className='container mt-5'>
                <div className="scroll-marquee" style={{ '--t': '60s' }}>
                    {Array(2).fill('').map((_, index) => (
                        <div key={index} className="marquee-inner">
                            {texts.map((text, idx) => (
                                <span key={idx}>"{text}"</span>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
