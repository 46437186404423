import React, { useEffect } from 'react';
import { LinearGradient } from 'react-text-gradients';
import { IoArrowUpCircleOutline } from "react-icons/io5";

export default function TermOfUse() {

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className='container mt-5'>
                <div className='row pt-5'>
                    <div className='col-md-12 pt-5'>
                        <p className='text-start pb-2 text-decoration-underline' style={{ fontSize: "1.5em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>TERMS OF USE</p>
                        <p className='text-start' style={{ fontSize: "1.3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Refer to our privacy policy.
                            </LinearGradient>
                        </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>These Terms of Use describe the terms and conditions applicable to your access and use of the website www.conceptteksolution.com and its E COMMERCE application platform (each a “Platform”).  This document is a legally binding agreement between you as the user(s) of the Platform (referred to as “you”, “your” or “User” hereinafter) and The Trusted Tex Deal LLP entity listed in clause 2.a below (referred to as “we”, “our” or “TheTexDeal” hereinafter).</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>1. Application and Acceptance of the Terms</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	User’s use of the Platform and conceptteksolution's services, software and products (collectively the “Services” hereinafter) is subject to the terms and conditions contained in this document as well as the Privacy Policy, The Product Listing Policy, IP Infringement Policy and any other rules and policies of the Platform that conceptteksolution may publish from time to time.  </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	This document and such other rules and policies of the Platform are collectively referred to below as the “Terms”.  By accessing the Platform or using the Services, User agrees to accept and be bound by the Terms.  Please do not use the Services or access the Platform if you do not accept all of the Terms or are unable to be bound by the Terms.  </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	User may not use the Services and may not accept the Terms if you are not of legal age to form a binding contract with conceptteksolution.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	You acknowledge and agree that conceptteksolution may amend any Terms at any time by posting the relevant amended and restated Terms on the Platform.  By continuing to use the Services or the Platform, you acknowledge to have accepted the amended Terms and agree that the amended Terms will apply to you.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	The Terms may not otherwise be modified except in writing by an authorized officer of conceptteksolution.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>2.	Provision of Services</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	The conceptteksolution contracting entity that you are contracting with for access to the Platform is The Trusted Tex Deal LLP. In case you avail services while accessing the Platform, that may be supported and/or provided by third party service provider(s), for all such services your contracting entity will be such third party service provider(s), as the case may be. conceptteksolution disclaims all liability for any claims that may arise pursuant to your use of services provided by such third party service provider(s).</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	You must register as a User on the Platform in order to access and use Services. Further, conceptteksolution reserves the right, without prior notice, to restrict access to or use of certain Services (or any features within the Services) subject to other conditions that conceptteksolution may impose in its discretion. </p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>3.	Fees and Charges Payable by Seller</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>Seller will pay to conceptteksolution, if applicable, fee for use and access of the Platform (referred to as ‘The Tex Deal Platform Fee’). The TexDeal Platform Fee will be ascertained based on the criteria determined by conceptteksolution and communicated to the seller, from time to time like Seller registration fee, Products upload fee. Advertisement fees etc.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>The applicable The TexDeal Platform Fee shall be as communicated by conceptteksolution to the seller(s), from time to time, via the Platform or through such other mode of communication as may be determined by conceptteksolution in its sole discretion. It shall be the seller’s responsibility to routinely check on such The TexDeal Platform Fee. In the event you continue to use the Platform, it shall be deemed that you have agreed to such change in the The TexDeal Platform Fee.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>The The TexDeal Platform Fee shall be exclusive of all applicable taxes (including Goods and Services Tax).</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>conceptteksolution may charge the Seller for any cancellation of the orders, and/or further services availed by Seller from conceptteksolution, including but not limited to, advertisement services.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>conceptteksolution shall issue the invoice for The Tex Deal Platform Fee and other charges (if any), on the Seller, and the Seller shall make payment of the same to conceptteksolution and the parties hereby agree that the Seller alone shall be responsible for ensuring that The Tex Deal Platform Fee is paid to conceptteksolution.  The Seller may however authorize any third party service provider engaged by the Seller (who may collect or receive payment for or from the Seller, and advise that a part thereof be remitted by such third party service provider as The Tex Deal Platform Fee to conceptteksolution on behalf of the Seller).</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>4.	Users Generally</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	You agree that (a) you will not copy, reproduce, download, re-publish, sell, distribute or resell any Services or any information, text, images, graphics, video clips, sound, directories, files, databases or listings, etc. available on or through the Platform (the “Platform Content”), and (b) you will not copy, reproduce, download, compile or otherwise use any Platform Content for the purposes of operating a business that competes with conceptteksolution, or otherwise commercially exploiting the Platform Content or systematic retrieval of Platform Content from the Platform to create or compile, directly or indirectly, a collection, compilation, database or directory (whether through robots, spiders, automatic devices or manual processes).</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	You must read conceptteksolution’s Privacy Policy which governs the protection and use of personal information about Users of Platform. You accept the terms of the Privacy Policy and agree to the use of the personal information about you in accordance with the Privacy Policy.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	conceptteksolution may allow Users access to content, products or services offered by third parties through hyperlinks (in the form of word link, banners, channels or otherwise), API or otherwise to such third parties' web sites. You are cautioned to read such third parties terms and conditions and/or privacy policies before using the Platform with respect to such content, products or services that you may avail. You acknowledge that conceptteksolution has no control over such third parties' web sites and shall not be responsible or liable to anyone for such web sites, or any content, products or services made available on such web sites.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	You agree not to undertake any action which may undermine the integrity of conceptteksolution's feedback system.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	By posting or displaying any information, content or material (“User Content”) on the Platform or providing any User Content to conceptteksolution or our representative(s), you grant perpetual, worldwide, royalty-free, and sub-licensable license to conceptteksolution to display, transmit, distribute, reproduce, publish, translate, and otherwise use any or all of the User Content in any form, media, or technology now known or not currently known in any manner and for any purpose which may be beneficial to the operation of the Platform, the provision of any Services and/or the business of the User. You confirm and warrant to conceptteksolution that you have all the rights, power and authority necessary to grant the above license.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>6.	User agree, undertake and confirm that User’s use of Platform shall be strictly governed by the following binding principles:</p>
                        <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                1.	User shall not host, display, upload, modify, publish, transmit, update or share any information which:
                            </LinearGradient>
                        </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	belongs to another person and to which User does not have any right to;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	is misleading in any way;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	harasses or advocates harassment of another person;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>6.	involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming" or messages using conceptteksolution communication platform;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>7.	promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>8.	infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>9.	promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>10.	contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>11.	provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>12.	provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>13.	contains video, photographs, or images of another person (with a minor or an adult).</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>14.	tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>15.	engages in commercial activities and/or sales without prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Platform. Throughout this Terms of Use, conceptteksolution's prior written consent means a communication coming from conceptteksolution's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>16.	solicits gambling or engages in any gambling activity which, in sole discretion, believes is or could be construed as being illegal;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>17.	interferes with another User's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>18.	refers to any website or URL that, in sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>19.	harm minors in any way;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>20.	infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>21.	violates any law for the time being in force;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>22.	deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>23.	impersonate another person;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>24.	contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancel-bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>25.	threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>26.	shall not be false, inaccurate or misleading;</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>27.	shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>28.	shall not create liability for conceptteksolution or cause conceptteksolution to lose (in whole or in part) the services of our internet service provider ("ISPs") or other suppliers</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>29.	You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>documents or information through any means not purposely made available through the Platform. conceptteksolution reserve its right to bar any such activity.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>30.	User shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>31.	Unless expressly permitted, User shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. User may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Platform, or any other user, including any account on the Platform not owned by User, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than User’s own information, as provided for by the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>5.	User Accounts</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	User must be registered on the Platform to access or avail the Services. Except with conceptteksolution's approval, one User may only register one account on the Platform. conceptteksolution may cancel or terminate a User’s account if conceptteksolution has reasons to suspect that the User has concurrently registered or controlled two or more accounts. Further, conceptteksolution may reject User’s application, without assigning any reasons thereof, for registration for any other reason.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	A set of User ID and OTP (One Time Password) / password is unique to a single account. You shall be solely responsible for maintaining the confidentiality and security of your user ID and password and for all activities that occur under User’s account. You agree that all activities that occur under your account (including without limitation, posting any company or product information, clicking to accept any terms & conditions or rules, subscribing to or making any payment for any services, sending emails using the Platform or other communications) will be deemed to have been authorized by you.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>6.	User’s Responsibilities</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	You represent, warrant and agree that (Ⅰ) you are at least eighteen (18) years of age or above and are fully able and competent to understand and agree to the Terms; (Ⅱ) you have full power and authority to accept the Terms, to grant the license and authorization (if applicable) and to perform the obligations hereunder; (Ⅲ) you use the Platform and Services for business purposes only; (Ⅳ) the address you provide when registering your account on the Platform is the principal place of business of your business entity; (Ⅴ) your business is validly existing and incorporated / established as per the provisions of applicable laws; (vi) you shall comply with all applicable laws while using and accessing the Platform; and (vii) you and products/ services provided by you on the Platform (if any) comply with applicable laws.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	User will be required to provide information or material about User’s entity, business or products/services as part of the registration process on the Platform or your use of any Service or the User account.  Each User represents, warrants and agrees that (a) such information and material whether submitted during the registration process or thereafter throughout the continuation of the use of the Platform or Service is true, accurate, current and complete, and (b) User will maintain and promptly amend all information and material to keep it true, accurate, current and complete.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	User may be required to furnish additional documents or information about products/ services that the User may offer for sale on the Platform in order to authenticate that the products offered for sale are genuine and authentic and do not infringe intellectual property rights or proprietary rights of any third party. User agrees to promptly provide such additional documents and information, failing conceptteksolution reserves its right to take appropriate measures as set out under Clause 7.  </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	User consents to the inclusion of the contact information about User in conceptteksolution's database. </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	You represents, warrants and agrees that (a) you shall be solely responsible for obtaining all necessary third party licenses and permissions (if any required) regarding any User Content that you submit, post or display; (b) any User Content that User submit, post or display does not infringe or violate any of the copyright, patent, trademark, trade name, trade secrets or any other personal or proprietary rights of any third party (“Third Party Rights”); (c) User have the right and authority (if required under applicable laws) to sell, trade, distribute or export or offer to sell, trade, distribute or export the products or services described in the User Content and such sale, trade, distribution or export or offer does not violate any Third Party Rights.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>7.	Breaches</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	conceptteksolution reserves the right in its sole discretion, without intimation to the User, remove, modify or reject any User Content that User submit to, post or display on the Platform which conceptteksolution reasonably believes is unlawful, violates the Terms, could subject conceptteksolution or its affiliates to liability, or is otherwise found inappropriate in conceptteksolution's opinion.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	If any User breaches any Terms, or if conceptteksolution has reasonable grounds to believe that a User is in breach of any Terms, conceptteksolution shall have the right to take such disciplinary actions as it deems appropriate, including without limitation: (i) suspending or terminating the User’s account and any and all accounts determined to be related to such account by conceptteksolution in its discretion; (ii) restricting, downgrading, suspending or terminating the subscription of, access to, or current or future use of any Service; (iii) removing any product listings or other User Content that the User has submitted, posted or displayed; and (v) any other corrective actions, discipline or penalties as conceptteksolution may deem necessary or appropriate in its sole discretion.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	Notwithstanding anything contained herein these Terms, conceptteksolution may with or without notice and in its sole discretion be entitled to suspend, de-activate, or de-list a User’s account or suspend any listing, or de-list any or all Product listing from the Platform for any reasons including without limitation, economic constraints, operational difficulties, financial implications, usage behaviour of the User on the Platform etc.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	In the event a User becomes inactive or if no transaction is noticed by conceptteksolution, in such a case conceptteksolution reserves its right to delist, deactivate or suspend a User’s account in its sole discretion, with or without giving any notice to the User.  </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	conceptteksolution reserves the right to cooperate fully with governmental authorities, private investigators and/or injured third parties in the investigation of any suspected criminal or civil wrongdoing.  Further, conceptteksolution may disclose the User's identity and contact information, if requested by a government or law enforcement body, an injured third party, or as a result of a subpoena or other legal action.  </p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>6.	Each User agrees to indemnify conceptteksolution, its affiliates, directors, employees, agents and representatives and to hold them harmless, from any and all damages, losses, claims and liabilities (including legal costs on a full indemnity basis) which may arise from your submission, posting or display of any User Content, from your use of the Platform or Services, or from your breach of the Terms.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>8.	Transactions Between Users</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	When a product is listed for sale on the Platform by a seller, Products sold to the buyer by such seller will be governed by individual contractual arrangement entered into directly between the seller and the buyer.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	For any Services, conceptteksolution does not represent either the seller or the buyer in specific transactions. conceptteksolution does not control and is not liable to or responsible for the quality, safety, lawfulness or availability of the products or services offered for sale on the Platform or the ability of the sellers to complete a sale or the ability of buyers to complete a purchase.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	Users agree that conceptteksolution cannot and does not confirm each User's purported identity. conceptteksolution encourages Users to exercise discretion and caution while dealing with various Users.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	Each User acknowledges that it is fully assuming the risks of conducting any purchase and sale transactions (hereinafter referred to as “Transaction Risk”) in connection with using the Platform or Services, and that it is fully assuming the risks of liability or harm of any kind in connection with subsequent activity of any kind relating to products or services that are the subject of transactions using the Platform.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	In the event that any User has a dispute with any party to a transaction, such User agrees to release and indemnify conceptteksolution (and our agents, affiliates, directors, officers and employees) from all claims, demands, actions, proceedings, costs, expenses and damages (including without limitation any actual, special, incidental or consequential damages) arising out of or in connection with such transaction. This clause shall also apply to any additional services opted for by the User by accessing any link from conceptteksolution site to avail of any additional or independent services related to the transaction of buy-sell conducted on the conceptteksolution Platform from any third party.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>9.	Limitation of Liability</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES PROVIDED BY THETEXDEAL ON OR THROUGH THE PLATFORM ARE PROVIDED "AS IS", "AS AVAILABLE" AND “WITH ALL FAULTS”, AND THETEXDEAL HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, ANY WARRANTIES OF CONDITION, QUALITY, DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. ALL SUCH WARRANTIES, REPRESENTATIONS, CONDITIONS, AND UNDERTAKINGS ARE HEREBY EXCLUDED.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	TO THE MAXIMUM EXTENT PERMITTED BY LAW, THETEXDEAL MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY, ACCURACY, CORRECTNESS, RELIABILITY, QUALITY, STABILITY, COMPLETENESS OR CURRENTNESS OF ANY INFORMATION PROVIDED ON OR THROUGH THE PLATFORM; THETEXDEAL DOES NOT REPRESENT OR WARRANT THAT THE MANUFACTURE, IMPORTATION, EXPORT, DISTRIBUTION, OFFER, DISPLAY, PURCHASE, SALE AND/OR USE OF PRODUCTS OR SERVICES OFFERED OR DISPLAYED ON THE PLATFORM DOES NOT VIOLATE ANY THIRD PARTY RIGHTS; AND THETEXDEAL MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING ANY PRODUCT OR SERVICE OFFERED OR DISPLAYED ON THE PLATFORM.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>10.	Force Majeure</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	Under no circumstances shall conceptteksolution be held liable for any delay or failure or disruption of the content or services delivered through the Platform resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable control, including without limitation, Internet failures, computer, telecommunications or any other equipment failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances, shortages of labor or materials, fires, flood, storms, explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or tribunals or non-performance of third parties.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>11.	Intellectual Property Rights</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	conceptteksolution is the sole owner or lawful licensee of all the rights and interests in the Platform and the Platform Content. All title, ownership and intellectual property rights in the Platform and Platform Content shall remain with conceptteksolution or licensors of the Platform Content, as the case may be. All rights not otherwise claimed under the Terms or by conceptteksolution are hereby reserved.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	"conceptteksolution" and any other related icons and logos are registered trademarks or trademarks or service marks of The Trusted Tex Deal LLP, in various jurisdictions and are protected under applicable copyright, trademark and other proprietary rights laws. The unauthorized copying, modification, use or publication of these marks is strictly prohibited.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>12.	Notices</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	All legal notices or demands to or upon conceptteksolution shall be made in writing and sent to TRUSTED TEX DEAL LLP, personally, by courier, certified mail, or facsimile to the following entity and address: 4TH Floor, S-4. Kartarpura Industrial Area, @@ Godam , Jaipur 302006 Rajasthan. Attn: Legal Department. The notices shall be effective when they are received by conceptteksolution in any of the above-mentioned manner.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	All legal notices or demands to or upon a User shall be effective if either delivered personally, sent by courier, certified mail, by facsimile or email to the last-known correspondence, fax or email address provided by the User to conceptteksolution, or by posting such notice or demand on an area of the Platform that is publicly accessible without a charge. Notice to a User shall be deemed to be received by such User if and when, a) conceptteksolution is able to demonstrate that communication, whether in physical or electronic form, has been sent to such User, or b) immediately upon conceptteksolution posting such notice on an area of the Platform that is publicly accessible without charge.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>13.	General Provisions</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>1.	Subject to any terms & conditions, the Terms constitute the entire agreement between User and conceptteksolution with respect to and govern User’s use of the Platform and Services, superseding any prior written or oral agreements in relation to the same subject matter herein.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>2.	conceptteksolution and User are independent contractors, and no agency, partnership, joint venture, employee-employer relationship is intended or created by the Terms.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>3.	If any provision of the Terms is held to be invalid or unenforceable, such provision shall be deleted and the remaining provisions shall remain valid and be enforced.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>4.	conceptteksolution's failure to enforce any right or failure to act with respect to any breach by User under the Terms will not constitute a waiver of that right nor a waiver of conceptteksolution's right to act with respect to subsequent or similar breaches.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>5.	conceptteksolution shall have the right to assign the Terms (including all of our rights, titles, benefits, interests, and obligations and duties in the Terms to any person or entity (including any affiliates of conceptteksolution). User may not assign, in whole or part, the Terms to any person or entity.</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>6.	The Terms shall be governed by the laws of India and the parties to the Terms agree to submit to the exclusive jurisdiction of the courts of Jaipur, Rajasthan, India.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>14.	 No Return policy.</p>
                        <p className='text-start pb-2' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>15.	Grievance Officer</p>
                        <p className='text-start pb-2' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#B2BABB" }}>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-12 ms-auto'>
                        <div className='card text-start bg-transparent'>
                            <p className='' style={{ fontSize: "1.3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Avinash Jain
                                </LinearGradient>
                            </p>
                            <p className='t' style={{ fontSize: "1.3em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution
                                </LinearGradient>
                            </p>
                            <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>36-A, Flat No. LG-1, Rameshwaram Colony</p>
                            <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>Opp  Aapni Dhani Restaurant, Sitabari</p>
                            <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>Tonk Road, Jaipur – 302019, Rajasthan, India</p>
                            <p className='' style={{ fontSize: ".8em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>Email: concepttekjpr@gmail.com</p>
                            <p className='' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>Time: Mon - Fri(11:00 AM - 06:00 PM)</p>
                        </div>
                    </div>
                </div>
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    )
}
