import React, { useEffect, useRef, useState } from 'react';
import { LinearGradient } from 'react-text-gradients';
import Typed from 'typed.js';
import { IoArrowUpCircleOutline } from "react-icons/io5";

import Map from '../images/gridMap.jpg';
import animLock from '../images/output-onlinegiftools.gif';
import team from '../images/team.jpg';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const ScrollAnimationComponent = () => {
    const sectionsRef = useRef([]);

    useEffect(() => {
        const sections = sectionsRef.current;

        sections.forEach((section, i) => {
            const test = section.querySelector(".test");
            ScrollTrigger.create({
                trigger: section,
                pin: true,
                pinSpacing: false,
            });
            if (test) {
                ScrollTrigger.create({
                    trigger: test,
                    start: "top bottom",
                    end: (self) => self.previous().start,
                    onToggle: () => test && test.classList.toggle("active"),
                });
            }
        });

        // Clean up on unmount
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    const texts = [
        "Inventory Management",
        "Club Management",
        "Attendance Management",
        "Android Applications",
        "Web Applications",
        "Tally Services",
    ];

    const typedElement = useRef(null);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex === texts.length - 1 ? 0 : prevIndex + 1));
        }, 3000);

        return () => clearInterval(interval);
    }, [texts]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    const testimonialRef1 = useRef(null);
    const testimonialRef2 = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const windowTopPosition = window.scrollY;
            const windowBottomPosition = windowTopPosition + windowHeight;

            const elements = [testimonialRef1.current, testimonialRef2.current];

            elements.forEach((element) => {
                if (!element) return;

                const elementHeight = element.offsetHeight;
                const elementTopPosition = element.offsetTop;
                const elementBottomPosition = elementTopPosition + elementHeight;

                if (
                    elementBottomPosition >= windowTopPosition &&
                    elementTopPosition <= windowBottomPosition
                ) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return (
        <div className='mt-5' >
            {/* <div className='comp-back'></div> */}
            <div className='mt-5' >
                    <div className='row pt-5'>
                        <div className='col-md-10 m-auto p-4'>
                            <p className='text-start pb-2 pt-5' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Concept Tek Solution</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution is an Indian software services company focusing on Software development, Automation, consultancy  and IT Products.
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution has its full-fledged offshore development center in Jaipur.
                                </LinearGradient>
                            </p>
                            <p className='text-start py-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Concept Tek Solution specializes in providing the following services:</p>
                            <div class="entry-content text-start">
                                <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        •	System Study & Design.
                                    </LinearGradient>
                                </p>
                                <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        •	Application Development
                                    </LinearGradient>
                                </p>
                                <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        •	Maintenance & Support
                                    </LinearGradient>
                                </p>
                                <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        •	Access Control  & IT Products
                                    </LinearGradient>
                                </p>
                                <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        •	System Integration, Conversion and Migration
                                    </LinearGradient>
                                </p>
                                <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                        •	Web enabling of Legacy Applications
                                    </LinearGradient>
                                </p>
                                <p className='text-start pt-2' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                    Concept Tek Solution provides a complete suite of IT services in the business applications domain, specializing in multiple verticals including MRP, Inventory services, HRMS Solutions,  education and allied industries.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10 m-auto p-4'>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Quality Focus</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution success in satisfying its customer’s systems from its commitment to a consistent methodology, effective project management techniques, proven automated tools, quality assurance, testing and dedicated professionals.
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    We follow rigorous quality management techniques, which along with our mature development processes ensure that a high quality is delivered in every phase of our software development and maintenance cycles. We have predefined processes for software development life cycle, quality assurance and documentation.
                                </LinearGradient>
                            </p>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Infrastructure</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution is currently located at 36-A, Flat No. LG-1, Rameshwaram Colony, Opp Aapni Dhani Restaurant, Sitabari, Tonk Road Jaipur, Rajasthan 302019, which operates as a full-fledged offshore development center.
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution state of the art offshore development center at Jaipur has optimum hardware, software and network infrastructure to carry out its activities and the requisite capability to scale up its infrastructure to satisfy its business and customer needs.
                                </LinearGradient>
                            </p>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>Business Model</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    We at Concept Tek Solution understand that every client has unique needs and different ways are required to fulfill them. We offer several business models for this very reason. Each of the models has been designed to offer maximum benefits to clients.
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Below are the varieties of business models that Concept Tek Solution offers to its customers to effectively fulfill their requirements, and eventually add value to the customer core business areas.
                                </LinearGradient>
                            </p>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-md-10 m-auto p-4'>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>The Offshore Model</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    This model enables clients to utilize the infrastructure and manpower of Concept Tek Solution in India to execute their projects.
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    To meet its client’s aggressive schedules, Concept Tek Solution presents the offshore outsourcing process that offers: –
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>
                                •	Resource management, to meet demanding project deadlines.
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>
                                •	Extensive project processes to meet different delivery needs of the customer.
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>
                                •	Extraordinary cost benefits when compared to the cost of an equivalent onsite team.
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 400, color: "#fff" }}>
                                Additional benefits to the customer in terms of reduced infrastructure required, to seat, connect and manage an onsite team.
                            </p>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>The Officesite and Onsite (optional)  Model</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution provides its software consultants to carry out onsite services. These resources, as a part of the onsite project team would report to Project Manager responsible for the project. The onsite model can be a component of the offsite model, where in, Concept Tek Solution will send its personnel to the client’s side for implementation, testing and support of various projects.
                                </LinearGradient>
                            </p>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>The offshore and Onsite Combination</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution could also carry entire programming work offshore and then undertake training, implementation and testing online or onsite at the customer site.
                                </LinearGradient>
                            </p>
                            <p className='text-start pb-2' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>The Virtual development cycle</p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    This is another useful concept, which works well under the onsite, offsite and offshore combination. It is useful to customers who are working against very tight deadlines and at the same time are unable to shore up their budget for getting the work done onsite.
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: "1em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Concept Tek Solution understands that there are certain key factors, like, time to market, ownership of source code and quality of solution which vary in degree from customer to customer, based on which we adopt a model which suits our customer the most.
                                </LinearGradient>
                            </p>
                        </div>
                    </div>
                {/* <div className='' style={{ height: '100vh', }} > </div> */}
            </div>
            <div className='' style={{ height: '0vh', }} > </div>
            <div className='container'>
                <div className='row mb-5 align-items-center d-flex'>
                    <div className='col-lg-7 col-md-6 col-sm-12 mb-3'>
                        <div className='card border-0 animation-element slide-up testimonial' ref={testimonialRef1}>
                            <div style={{ backgroundImage: `url(${team})`, width: "100%", height: "50vh", backgroundPosition: "center", backgroundSize: "cover", objectFit: "cover", borderRadius: 5 }} >
                                <div style={{ background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), #000 )", position: "relative", height: "100%", borderRadius: 5, alignItems: "flex-end", display: "flex" }} >
                                    <div className=''>
                                        <p className='text-center p-4' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 500, color: "#fff" }}>
                                            Technology & Innovation
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 ms-auto'>
                        <div className='card p-5' style={{ width: "100%", backgroundColor: "#fff" }} >
                            <p className='text-center pb-3' style={{ fontSize: "1.2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#fff" }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Meet our Founder, Avinash Jain
                                </LinearGradient>
                            </p>
                            <p className='text-start' style={{ fontSize: ".9em", fontFamily: "Noto Sans", fontWeight: 400, color: "#000" }}>We started our journey in 1990 as a software developing company and scaled ourselves as one of the best software developers in the State of Rajasthan, mainly Jaipur. With over thirty years of experience we have been delivering world class services all over India. We help our clients grow their business digitally. Our skilled and well trained team is capable of handling any complex task during development and after sale services. Our main focus is to provide best after sale services of our softwares for the utmost satisfaction of the customers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='' style={{ height: '10vh' }} > </div>
            <div className='container'>
                <div className='row '>
                    {/* <div className='col-lg-8 col-md-6 col-sm-12 me-auto'>
                        <div className='d-flex align-items-center'>
                            <p className='text-start pb-2 pe-2' style={{ fontSize: "3em", fontFamily: "Noto Sans", fontWeight: 600, }}>
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Services.
                                </LinearGradient>
                            </p>
                            <p className="animate-text text-start">
                                {texts.map((text, i) => (
                                    <span key={i} className={i === index ? 'text-in' : 'text-out'}>
                                        {text}
                                    </span>
                                ))}
                            </p>
                        </div>
                    </div> */}
                </div>
                        <p className='text-center mb-3' style={{ fontSize: "2em", fontFamily: "Noto Sans", fontWeight: 600, color: "#B2BABB", cursor: "pointer" }}>Our Presence</p>
            </div>
            <div className='img_container anime_img' style={{}} >
                <img src={Map} alt="" style={{ width: "100%", objectFit: "cover" }} />
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    );
};

export default ScrollAnimationComponent;
