import React from 'react';

import Adhira from '../images/clientLogos/aadira logo.jpg';
import AClogo from '../images/clientLogos/AC Logo.png';
import Aratari from '../images/clientLogos/Aari Tari.png';
import Aura from '../images/clientLogos/Aura.jpg';
import Anenex from '../images/clientLogos/Annex Creation.jpeg';
import Benaaz from '../images/clientLogos/Benaaz Collection.png';
import Bhagwati from "../images/clientLogos/Bhagwati Fashions Pvt. Ltd..png";
import Choteylal from '../images/clientLogos/Chhoteylal Nemichand Sogani.png';
import Daaman from '../images/clientLogos/Daaman.jpg';
import DevTextile from '../images/clientLogos/Dev Textiles.png';
import DsgnWorld from '../images/clientLogos/Design world.jpg';
import Flo from '../images/clientLogos/Flo.jpeg';
import FabStyle from '../images/clientLogos/fabstyle.png';
import Ganpati from '../images/clientLogos/Ganpati Exclusive Designer Sarees Pvt. Ltd..png';
import GlobalInd from '../images/clientLogos/globalIndia.png';
import Heerawat from '../images/clientLogos/Hirawat.png';
import Kamya from '../images/clientLogos/Kamya.jpg';
import KkSaree from '../images/clientLogos/KK Saree.png';
import Madhuri from '../images/clientLogos/Madhuri.png';
import MahalxmiSari from '../images/clientLogos/Mahalaxmi Saree Emporium.png';
import MahalaxmiTax from '../images/clientLogos/MAHALAXMI TEX FAB_page-0002.jpg';
import MaharaniSari from '../images/clientLogos/Maharani Saree Emporium.png';
import ManishDsgnr from '../images/clientLogos/Manish Designers Pvt. Ltd..png';
import MeghDoot from '../images/clientLogos/Meghdoot.png';
import MSBandhani from '../images/clientLogos/MS Bandhani.png';
import Navratan from '../images/clientLogos/Navratan Bandhani.png';
import Nikhar from '../images/clientLogos/Nikhaar Collection.png';
import Noya from '../images/clientLogos/Noya.png';
import Padmawati from '../images/clientLogos/Padmavati Sarees.png';
import RadheKrishn from '../images/clientLogos/Radhe Kirshna Silks, Bangalore.png';
import Rajvesh from '../images/clientLogos/Rajvesh.png';
import RAMAS from '../images/clientLogos/RAMAS.jpeg';
import RanasLegacy from '../images/clientLogos/Ranas Legacy.png';
import Ranas from '../images/clientLogos/Ranas.png';
import Roop from '../images/clientLogos/Roop Fashion.png';
import RRFashion from '../images/clientLogos/RR Fashions.png';
import RSFashin from '../images/clientLogos/RS Fashion.png';
import Sampat from '../images/clientLogos/SAMPATSACHIN.jpg';
import Shivani from '../images/clientLogos/SHIVANI CREATION.jpeg';
import ShriKanha from '../images/clientLogos/Shree Kanha Fashions.png';
import ShriKrishnam from '../images/clientLogos/SHRI KRISHNAM.jpg';
import ShriNarayan from '../images/clientLogos/Shri Narayan Collection.png';
import SKSari from '../images/clientLogos/SK Saree Palace.png';
import SREnterprises from '../images/clientLogos/SR Enterprises.png';
import Surekha from '../images/clientLogos/Surekha Delhi.jpg';
import Vimal from '../images/clientLogos/Vimal Creations.png';
import Aamori from '../images/clientLogos/AAMORI.png';
import Akshay from '../images/clientLogos/AKSHAY AGENCY.png';
import Albeli from '../images/clientLogos/ALBELI.png';
import Amit from '../images/clientLogos/AMIT SAREE.png';
import Bhuramal from '../images/clientLogos/BHURAMAL.jpg';
import Ethinic from '../images/clientLogos/ETHNIC EDGE.png';
import FabStory from '../images/clientLogos/FAB STORY.jpg';
import Ikkat from '../images/clientLogos/IKKAT.jpg';
import Islie from '../images/clientLogos/ISLIE.png';
import Shakshi from '../images/clientLogos/SAKSHI.jpg';
import Silaai from '../images/clientLogos/SILAAI.png';
import Swaraj from '../images/clientLogos/SWARAJ.jpg';
import Tina from '../images/clientLogos/TINAFASHION.jpg';

export default function ETClientList() {

    // const images = [
    //     Adhira, Aamori, Akshay, AClogo, Aura, Albeli, Amit, Aratari, Anenex, Benaaz, Bhuramal, Bhagwati, Choteylal, Daaman, DevTextile, DsgnWorld, Ethinic, Flo, FabStyle, FabStory, Ganpati, GlobalInd,
    //     Heerawat, Ikkat, Islie, Kamya, KkSaree, Madhuri, MahalxmiSari, MahalaxmiTax, MaharaniSari, ManishDsgnr,
    //     MeghDoot, MSBandhani, Navratan, Nikhar, Noya, Padmawati, RadheKrishn, Rajvesh, RAMAS,
    //     RanasLegacy, Ranas, Roop, RRFashion, RSFashin, Sampat, Shivani, ShriKanha, ShriKrishnam, Shakshi, Silaai,
    //     ShriNarayan, SKSari, SREnterprises, Surekha, Swaraj, Tina, Vimal
    //   ];
    const images = [
        { src: Adhira, name: "Adhira Fashion" },
        { src: Aamori, name: "Aamori Studio" },
        { src: Akshay, name: "Akshay Agency" },
        { src: AClogo, name: "Aashvi Couture" },
        { src: Aura, name: "Aura" },
        { src: Albeli, name: "Albeli Trends" },
        { src: Amit, name: "Amit Saree" },
        { src: Aratari, name: "Aari Tari" },
        { src: Anenex, name: "Annex" },
        { src: Benaaz, name: "Benaaz Collection" },
        { src: Bhuramal, name: "Bhuramal Rameshwar Lal" },
        { src: Bhagwati, name: "Bhagwati Fashions Pvt. Ltd." },
        { src: Choteylal, name: "Sogani's" },
        { src: Daaman, name: "Daamann" },
        { src: DevTextile, name: "Dev Textiles" },
        { src: DsgnWorld, name: "Design World" },
        { src: Ethinic, name: "Ethnic Edge" },
        { src: Flo, name: "Fashion | Lifestyle | Outfit" },
        { src: FabStyle, name: "FabStyle" },
        { src: FabStory, name: "FabStory" },
        { src: Ganpati, name: "Ganpati Exclusive" },
        { src: GlobalInd, name: "Global India" },
        { src: Heerawat, name: "Hirawat" },
        { src: Ikkat, name: "Ikkat The Women World" },
        { src: Islie, name: "Islie" },
        { src: Kamya, name: "Kamya Fashion" },
        { src: KkSaree, name: "KK Saree" },
        { src: Madhuri, name: "Madhuri" },
        { src: MahalxmiSari, name: "Mahalaxmi Saree Emporium" },
        { src: MahalaxmiTax, name: "Mahalaxmi Tax Fab" },
        { src: MaharaniSari, name: "Maharani Saree Emporium" },
        { src: ManishDsgnr, name: "Manish Designer Pvt. Ltd." },
        { src: MeghDoot, name: "Meghdoot" },
        { src: MSBandhani, name: "M.S. Bandhani" },
        { src: Navratan, name: "Navratan Bandhani Fashion Prints" },
        { src: Nikhar, name: "Nikhaar Collections" },
        { src: Noya, name: "Noya" },
        { src: Padmawati, name: "Padmawati Sarees Fashion" },
        { src: RadheKrishn, name: "Shagun Handloom Sarees" },
        { src: Rajvesh, name: "Rajvesh Jaipur" },
        { src: RAMAS, name: "RAMA'S" },
        { src: RanasLegacy, name: "Ranas Legacy" },
        { src: Ranas, name: "Ranas Dressing Royale" },
        { src: Roop, name: "Roop Fashion" },
        { src: RRFashion, name: "RR Fashion" },
        { src: RSFashin, name: "RS Fashion" },
        { src: Sampat, name: "Sampat Sachin" },
        { src: Shivani, name: "Bairaas" },
        { src: ShriKanha, name: "Shri Kanha Fashion" },
        { src: ShriKrishnam, name: "Shri Krishnam" },
        { src: Shakshi, name: "Sakshi Vogue India" },
        { src: Silaai, name: "Silaai" },
        { src: ShriNarayan, name: "Shri Narayan Collections" },
        { src: SKSari, name: "SK Saree Palace" },
        { src: SREnterprises, name: "S.R. Enterprises" },
        { src: Surekha, name: "Surekha a Symbol of Designing" },
        { src: Swaraj, name: "Swaraj Garments" },
        { src: Tina, name: "Tina Fashion" },
        { src: Vimal, name: "Vimal Creation" },
    ];
    return (
        <div>
            <div className='container-fluid g-0'>
                <div className='row g-2'>
                    {images.map((item, index) => (
                        <div key={index} className='col-xl-2 col-lg-2 col-md-6 col-sm-12'>
                            <div className='card d-flex align-items-center justify-content-center rounded-0 client-card' style={{ width: "100%", height: 200 }}>
                                <img src={item.src} alt={item.name} className='img-fluid' />
                                <p className='text-center mt-2' style={{ color: "#2e4053", fontSize: "16px", fontWeight: 500 }}>{item.name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
