import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { RiMenu2Fill } from "react-icons/ri";
import { LinearGradient } from 'react-text-gradients'

import logo from '../images/newlogo.png';

// import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";

export default function Navbar() {

    const navigate = useNavigate()
    const [click, setClick] = useState(false);

    const handleClick = () => { setClick(!click); setShow(false) };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <div>
            <nav class="nav-bar fixed-top">
                <div class="icon-nav">
                    <i class="fas fa-moon"></i>
                    <span class="logo" onClick={() => navigate('/Home')} style={{ cursor: "pointer" }} >
                        <img src={logo} alt="" style={{ width: "100%", height: 80, }} />
                    </span>
                </div>
                <ul className={`list-nav-bar ${menuActive ? 'active' : ''}`}>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/Product"
                            activeClassName="active"
                            className="nav-links"
                            onClick={handleClick}
                        >
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Solutions
                            </LinearGradient>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/Service"
                            activeClassName="active"
                            className="nav-links"
                            onClick={handleClick}
                        >
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Services
                            </LinearGradient>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/Clients"
                            activeClassName="active"
                            className="nav-links"
                            onClick={handleClick}
                        >
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Clients
                            </LinearGradient>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/Company"
                            activeClassName="active"
                            className="nav-links"
                            onClick={handleClick}
                        >
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Company
                            </LinearGradient>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            exact
                            to="/Contact"
                            activeClassName="active"
                            className="nav-links"
                            onClick={handleClick}
                        >
                            <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                Contact
                            </LinearGradient>
                        </NavLink>
                    </li>
                </ul>
                <div class="fas burger-menu" id="burger-menu" onClick={toggleMenu} style={{ fontSize: "2em" }}>{menuActive ? '×' : '☰'}</div>
            </nav>
            {/* <!-- Sidebar -->  */}
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="text-dark">
                        <img src={logo} alt="" style={{ width: "100%", height: 60, }} />
                        {/* <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                            CONCEPTTEK
                        </LinearGradient> */}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul class="navbar-nav m-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/Product"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Solutions
                                </LinearGradient>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/Service"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Services
                                </LinearGradient>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/Clients"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Clients
                                </LinearGradient>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/Company"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Company
                                </LinearGradient>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                exact
                                to="/Contact"
                                activeClassName="active"
                                className="nav-links"
                                onClick={handleClick}
                            >
                                <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                                    Contact
                                </LinearGradient>
                            </NavLink>
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}        