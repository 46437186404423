import React from 'react';
import Slider from 'react-slick';
import './TestimonialCss.css';
import { FaQuoteLeft } from "react-icons/fa";

const testimonials = [
  {
    id: 1,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus optio facilis beatae.",
    imgUrl: "https://i.ibb.co/hKgs8gm/profile.jpg",
    name: "John Doe",
    job: "MANAGER",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus optio facilis beatae.",
    imgUrl: "https://i.ibb.co/hKgs8gm/profile.jpg",
    name: "John Doe",
    job: "CEO",
  },
  {
    id: 3,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus optio facilis beatae.",
    imgUrl: "https://i.ibb.co/hKgs8gm/profile.jpg",
    name: "John Doe",
    job: "Co-Founder",
  },
  {
    id: 4,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus optio facilis beatae.",
    imgUrl: "https://i.ibb.co/hKgs8gm/profile.jpg",
    name: "John Doe",
    job: "SEO",
  }
];

const TestimonialSlider = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 600,
    draggable: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <main>
      {/* <section> */}
        <div className="testimonial">
          <div className="container">
            <div className="testimonial__inner">
              <Slider {...settings}>
                {testimonials.map(testimonial => (
                  <div key={testimonial.id} className="testimonial-slide">
                    <div className="testimonial_box">
                      <div className="testimonial_box-inner">
                        <div className="testimonial_box-top">
                          <div className="testimonial_box-icon">
                            {/* <i className="fas fa-quote-right"></i> */}
                            <FaQuoteLeft className='fa-quote-right' />
                          </div>
                          <div className="testimonial_box-text">
                            <p>{testimonial.text}</p>
                          </div>
                          <div className="testimonial_box-img" style={{ }}>
                            <img src={testimonial.imgUrl} alt="profile" />
                          </div>
                          <div className="testimonial_box-name">
                            <h4>{testimonial.name}</h4>
                          </div>
                          <div className="testimonial_box-job">
                            <p>{testimonial.job}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      {/* </section> */}
    </main>
  );
};

export default TestimonialSlider;
