import React, { useEffect } from 'react';
import { LinearGradient } from 'react-text-gradients';
import ClientNameSlide from './ClientNameSlide';
import ETClientList from './ETClientList';
import ETClubClients from './ETClubClients';
import { IoArrowUpCircleOutline } from "react-icons/io5";

export default function Clients() {

    useEffect(() => {
        scrollToTop();
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            <div className='container mt-5'>
                <div className='row py-5'>
                    <div className='text-center mt-5 pb-5' style={{ fontSize: "2em", fontWeight: 600, }}>
                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                            Our Easy Trade Clients
                        </LinearGradient>
                    </div>
                    <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                </div>
                <ETClientList />
                <ClientNameSlide />
            </div>
            <div className='container mt-5'>
                <div className='row py-5'>
                    <div className='text-center mt-5 pb-5' style={{ fontSize: "2em", fontWeight: 600, }}>
                        <LinearGradient gradient={['to left', '#17acff ,#ff68f0']}>
                            Our Easy Club Clients
                        </LinearGradient>
                    </div>
                    <hr style={{ backgroundColor: "#B2BABB", height: "1px", border: "none", margin: 0 }} />
                </div>
                {/* <ETClientList /> */}
                <ETClubClients />
            </div>
            <button className="scroll-to-top-button border-0 rounded-2" onClick={scrollToTop} style={{ width: 40, height: 40, backgroundColor: "#ff68f0", color: "#fff", alignItems: "center", justifyContent: "center", display: "flex", }}><IoArrowUpCircleOutline style={{ fontSize: 40 }} /></button>
        </div>
    )
}
